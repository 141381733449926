// store/modules/user.ts
import { app } from "@/services";

export interface ItemI {
  _id?: string,
  name: string,
  plural: string,
  singular: string,
  description: string
}

const servicename = "user-groups";
const module = {
  namespaced: true as true,
  state: {
    items: []
  },
  actions: {
    async create({}, payload: any) {
      const {data, callback} = payload;
      try {
        const item = await app.service(servicename).create(data);
        if (callback) callback(null, item);
      } catch (error) {
        if (callback) callback(error);
      }
    }, 
    async patch({}, payload: any) {
      const {id, data, callback} = payload;
      try {
        const item = await app.service(servicename).patch(id, data);
        if (callback) callback(null, item);
      } catch (error) {
        if (callback) callback(error);
      }
    },
    async remove({}, payload: any) {
      const {id, callback} = payload;
      try {
        const item = await app.service(servicename).remove(id);
        if (callback) callback(null, item);
      } catch (error) {
        if (callback) callback(error);
      }
    },
    async get({}, payload: any) {
      const {id, callback} = payload;
      try {
        const item = await app.service(servicename).get(id);
        if (callback) callback(null, item);
      } catch (error) {
        if (callback) callback(error);
      }
    },
    async load(store: any) {
      try {
        const res = await app.service(servicename).find({query: {$paginate: false}});
        store.commit("setItems", res)
      } catch (error) {
        store.commit("setItems", [])
      }
    }
  },
  mutations: {
    setItems(state: any, data: ItemI[]) {
      state.items = data;
    },
    addItem(state: any, item: ItemI) {
      state.items.push(item);
    },
    removeItem(state: any, item: ItemI) {
      const oitem = state.items.filter((i: ItemI) => i._id && item._id && i._id.toString() === item._id.toString())[0];
      if (oitem) {
        const index = state.items.indexOf(oitem);
        state.items.splice(index, 1);
      }
    },
    patchItem(state: any, item: ItemI) {
      const oitem = state.items.filter((i: ItemI) => i._id && item._id && i._id.toString() === item._id.toString())[0];
      if (oitem) {
        const index = state.items.indexOf(oitem);
        state.items.splice(index, 1, item);
      }
    }
  }
}

export function registerEvents(store: any, path: string) {
  app.service(servicename).on('created', (item: ItemI) => {
    store.commit(`${path}/addItem`, item);
  })

  app.service(servicename).on('removed', (item: ItemI) => {
    store.commit(`${path}/removeItem`, item);
  })

  app.service(servicename).on('patched', (item: ItemI) => {
    store.commit(`${path}/patchItem`, item);
  })

  app.service(servicename).on('updated', (item: ItemI) => {
    store.commit(`${path}/patchItem`, item);
  })
}

export default module;
