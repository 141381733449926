// store/modules/user.ts
import { app } from "@/services";

export interface ItemI {
  _id?: string,
  name: string,
  plural: string,
  singular: string,
  description: string
}

const servicename = "settings";
const module = {
  namespaced: true as true,
  state: {
    items: []
  },
  actions: {
    async create({}, payload: any) {
      const {data, callback} = payload;
      try {
        const item = await app.service(servicename).create(data);
        if (callback) callback(null, item);
      } catch (error) {
        if (callback) callback(error);
      }
    }, 
    async patch({}, payload: any) {
      const {id, data, callback} = payload;
      try {
        const item = await app.service(servicename).patch(id, data);
        if (callback) callback(null, item);
      } catch (error) {
        if (callback) callback(error);
      }
    },
    async remove({}, payload: any) {
      const {id, callback} = payload;
      try {
        const item = await app.service(servicename).remove(id);
        if (callback) callback(null, item);
      } catch (error) {
        if (callback) callback(error);
      }
    },
    async get({}, payload: any) {
      const {id, callback} = payload;
      try {
        const item = await app.service(servicename).get(id);
        if (callback) callback(null, item);
      } catch (error) {
        if (callback) callback(error);
      }
    },
    async load(store: any, callback: any) {
      try {
        const res = await app.service(servicename).find({query: {$paginate: false}});
        store.commit("setItems", res)
        if (callback) callback(null, res)
      } catch (error) {
        store.commit("setItems", [])
        if (callback) callback(error)
      }
    },
    async saveVoucherNumbering({state}: any, payload: any) {
      const {prefix, suffix, precision, counter, callback} = payload;

      const items = state.items;
      const prefix_settings = items.filter((i: any) => i.name === "voucher-numbering-prefix")[0]
      const suffix_settings = items.filter((i: any) => i.name === "voucher-numbering-suffix")[0]
      const precision_settings = items.filter((i: any) => i.name === "voucher-numbering-precision")[0]
      const counter_settings = items.filter((i: any) => i.name === "voucher-numbering-counter")[0]

      if (prefix_settings) {
        await app.service("settings").patch(prefix_settings._id, {$set: {value: prefix}})
      }

      if (suffix_settings) {
        await app.service("settings").patch(suffix_settings._id, {$set: {value: suffix}})
      }

      if (precision_settings) {
        await app.service("settings").patch(precision_settings._id, {$set: {value: precision}})
      }

      if (counter_settings) {
        await app.service("settings").patch(counter_settings._id, {$set: {value: counter}})
      }

      if (callback) callback(null, {prefix, suffix, precision, counter})

    }
  },
  mutations: {
    setItems(state: any, data: ItemI[]) {
      state.items = data;
    },
    addItem(state: any, item: ItemI) {
      state.items.push(item);
    },
    removeItem(state: any, item: ItemI) {
      const oitem = state.items.filter((i: ItemI) => i._id && item._id && i._id.toString() === item._id.toString())[0];
      if (oitem) {
        const index = state.items.indexOf(oitem);
        state.items.splice(index, 1);
      }
    },
    patchItem(state: any, item: ItemI) {
      const oitem = state.items.filter((i: ItemI) => i._id && item._id && i._id.toString() === item._id.toString())[0];
      if (oitem) {
        const index = state.items.indexOf(oitem);
        state.items.splice(index, 1, item);
      }
    }
  },
  getters: {
    nextVoucherNumber(state: any) {
      const items = state.items;
      const prefix_settings = items.filter((i: any) => i.name === "voucher-numbering-prefix")[0]
      const suffix_settings = items.filter((i: any) => i.name === "voucher-numbering-suffix")[0]
      const precision_settings = items.filter((i: any) => i.name === "voucher-numbering-precision")[0]
      const counter_settings = items.filter((i: any) => i.name === "voucher-numbering-counter")[0]
      
      let curNum: any = counter_settings ? Number(counter_settings.value) + 1 : 1
      curNum = curNum.toString()
      const curLen = curNum.length
      const prec = precision_settings ? Number(precision_settings.value) : 0

      if (prec > curLen) {
        curNum = curNum.padStart(prec - curLen, "0")
      }

      return `${prefix_settings ? prefix_settings.value : ""}${curNum}${suffix_settings ? suffix_settings.value : ""}`
    }
  }
}

export function registerEvents(store: any, path: string) {
  app.service(servicename).on("created", (item: ItemI) => {
    store.commit(`${path}/addItem`, item);
  })

  app.service(servicename).on("removed", (item: ItemI) => {
    store.commit(`${path}/removeItem`, item);
  })

  app.service(servicename).on("patched", (item: ItemI) => {
    store.commit(`${path}/patchItem`, item);
  })

  app.service(servicename).on("updated", (item: ItemI) => {
    store.commit(`${path}/patchItem`, item);
  })
}

export default module;
