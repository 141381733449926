import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;

Vue.use(Element)

declare global {
  interface Number {
    format(n: number, x: number): string;
  }
}

Number.prototype.format = function(n = 2, x = 3) {
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
