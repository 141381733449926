// src/services/index.ts
/**
 * Services module is a merger of feathers client and keycloak instance. The idea is to have the rest api access and keycloak service coupled together.
  * @packageDocumentation
   */
// import io from "socket.io-client";
import feathers, { Application } from "@feathersjs/feathers";
import findOne from 'feathers-findone';
// import socketio from "@feathersjs/socketio-client";
import rest from "@feathersjs/rest-client";
import auth from "@feathersjs/authentication-client";

const APIURL = process.env.VUE_APP_REST_API_URL || "http://localhost:3031";

// const socket = io(APIURL);
const restClient = rest(APIURL);
export const app: Application = feathers()
  .configure(restClient.fetch(window.fetch))
  .configure(auth({ storage: window.sessionStorage }))
  .configure(findOne());
