import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    redirect: "/dashboard/overview",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    children: [
      {
        path: "overview",
        name: "Overview",
        component: () => import(/* webpackChunkName: "overview" */ "../views/Overview.vue")
      },
      {
        path: "masters/unit",
        name: "Unit",
        meta: {
          permission: "units:*"
        },
        component: () => import(/* webpackChunkName: "unit" */ "../views/Unit.vue")
      },
      {
        path: "masters/stock-group",
        name: "StockGroup",
        meta: {
          permission: "stock-groups:*"
        },
        component: () => import(/* webpackChunkName: "stockgroup" */ "../views/StockGroup.vue")
      },
      {
        path: "masters/stock-item",
        name: "StockItem",
        meta: {
          permission: "stock-items:*"
        },
        component: () => import(/* webpackChunkName: "stockitem" */ "../views/StockItem.vue")
      },
      {
        path: "masters/machine",
        name: "Machine",
        meta: {
          permission: "machines:*"
        },
        component: () => import(/* webpackChunkName: "machine" */ "../views/Machine.vue")
      },
      {
        path: "reports/daybook",
        name: "DayBookReport",
        meta: {
          permission: "reports:*"
        },
        component: () => import(/* webpackChunkName: "daybookreport" */ "../views/DayBookReport.vue")
      },
      {
        path: "reports/machine-wise",
        name: "MachineWiseReport",
        meta: {
          permission: "reports:*"
        },
        component: () => import(/* webpackChunkName: "machinewisereport" */ "../views/MachineWiseReport.vue")
      },
      {
        path: "reports/item-wise",
        name: "ItemWiseReport",
        meta: {
          permission: "reports:*"
        },
        component: () => import(/* webpackChunkName: "itemwisereport" */ "../views/ItemWiseReport.vue")
      },
      {
        path: "reports/item-list",
        name: "ItemList",
        meta: {
          permission: "reports:*"
        },
        component: () => import(/* webpackChunkName: "itemlist" */ "../views/ItemListReport.vue")
      },
      {
        path: "security/group",
        name: "UserGroup",
        meta: {
          permission: "user-groups:*"
        },
        component: () => import(/* webpackChunkName: "usergroup" */ "../views/UserGroup.vue")
      },
      {
        path: "security/user",
        name: "User",
        meta: {
          permission: "users:*"
        },
        component: () => import(/* webpackChunkName: "user" */ "../views/User.vue")
      },
      {
        path: "settings",
        name: "Settings",
        meta: {
          permission: "settings:*"
        },
        component: () => import(/* webpackChunkName: "settings" */ "../views/Setting.vue")
      },
      {
        path: "transactions/manufacturing",
        name: "Manufacturing",
        meta: {
          permission: "vouchers:*"
        },
        component: () => import(/* webpackChunkName: "manufacturing" */ "../views/Manufacturing.vue")
      },
      {
        path: "transactions/manufacturing/:voucherId/:mode",
        name: "ManufacturingAction",
        meta: {
          permission: "vouchers:*"
        },
        component: () => import(/* webpackChunkName: "manufacturing-action" */ "../views/Manufacturing.vue")
      },
    ],
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});


router.beforeResolve((to, from, next) => {
  if (to.path === '/') next();
  else {
    store.dispatch("authenticate", {
      force: true,
      callback: (e: any, d: any) => {
        if (d) {
          const pathSecurity = to.meta ? to.meta.permission : null
          if (pathSecurity) {
            const { user } = d;
            const isSuper = user ? user.isSuper : false;
            const permissions: Array<string> = user && user.group ? user.group.permissions : [];
            if (isSuper) next();
            else {
              if (permissions.includes(pathSecurity)) next()
              else next("/");
            }
          } else {
            next();
          }
        } else {
          next(`/?redirect=${to.path}`);
        }
      }})
  }
})

export default router;
