import Vue from "vue";
import Vuex, { Store } from "vuex";
import { app } from "../services";
import unit, { registerEvents as unitEvents } from "@/store/modules/unit";
import machine, { registerEvents as machineEvents } from "@/store/modules/machine";
import stockgroup, { registerEvents as stockgroupEvents } from "@/store/modules/stockgroup";
import stockitem, { registerEvents as stockitemEvents } from "@/store/modules/stockitem";
import user, { registerEvents as userEvents } from "@/store/modules/user";
import usergroup, { registerEvents as usergroupEvents } from "@/store/modules/usergroup";
import voucher, { registerEvents as voucherEvents } from "@/store/modules/voucher";
import setting, { registerEvents as settingEvents } from "@/store/modules/setting";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    showMsg: false,
    msgColor: "success",
    msgText: "",
    footer: {
      mode: "overview",
      show: false
    }
  },
  mutations: {
    "SET_USER": (state, data) => {
      state.user = data;
    },
    "SHOW_SUCCESS": (state, msg) => {
      state.msgText = msg;
      state.showMsg = true;
      state.msgColor = "success";
    },
    "SHOW_MESSAGE": (state, msg) => {
      state.msgText = msg;
      state.showMsg = true;
      state.msgColor = "secondary";
    },
    "SHOW_ERROR": (state, msg) => {
      state.msgText = msg;
      state.showMsg = true;
      state.msgColor = "error";
    },
    "FOOTER_VISIBLE": (state, value: boolean) => {
      state.footer.show = value
    }
  },
  actions: {
    async login ({commit}, {data, callback} ) {
      try {
        const payload: any = await app.authenticate(data);
        const { user } = payload;
        commit('SET_USER', user);
        if (callback) callback(null, user);
      } catch (error) {
        if (callback) callback(error);
      }
    },
    async authenticate ({commit}, { force, callback }) {
      try {
        const payload: any = await app.reAuthenticate(force);
        if (callback) callback(null, payload)
        const { user } = payload;
        commit('SET_USER', user);
      } catch (error) {
        if (callback) callback(error);
      }
    },
    async logout({commit}, callback) {
      const res = await app.logout();
      commit("SET_USER", null);
      if (callback) callback(null, res);
    }
  },
  modules: {
    unit,
    machine,
    stockgroup,
    stockitem,
    users: user,
    usergroup,
    voucher,
    setting
  }
});

unitEvents(store, "unit")
machineEvents(store, "machine")
stockgroupEvents(store, "stockgroup")
stockitemEvents(store, "stockitem")
userEvents(store, "users")
usergroupEvents(store, "usergroup")
voucherEvents(store, "voucher")
settingEvents(store, "setting")

export default store;
