










































import { Component, Vue } from "vue-property-decorator";

@Component({ name: "Home" })
export default class Home extends Vue {
  name = "Giles Tetteh";
  loginInfo = { email: "", password: "", strategy: "local" };
  signin() {
    this.$store.dispatch("login", { data: this.loginInfo, callback: this.onSignIn });
  }

  onSignIn(e?: any, d?: any) {
    if (d) {
      if (this.$route.query && this.$route.query.redirect) {
        const redirect: string = this.$route.query.redirect.toString();
        this.$router.replace(redirect);
      } else {
        this.$router.replace('/dashboard');
      }
    } else {
      this.$store.commit("SHOW_MESSAGE", e.message)
    }
  }
}
